import React, { useState, useEffect, useRef } from 'react';
import './App.css';
import videoSource1 from './Clarios_Connected Services_Video 01_1920x1080_Master_v14.mp4';
import videoSource2 from './Clarios_Connected Services_Video 02_Chapter 01_1920x1080_Master_v08.mp4';
import videoSource3 from './Clarios_Connected_Services_Video 02_Chapter 02_1920x1080_Master_Logo+Tag CTA_v08.mp4';
import videoSource4 from './E3D_Clarios_Connected Services_Video 02_Chapter_03_1920x1080_Master_Logo+Tag Text CTA_v08.mp4';
import arrowLeft from './arrow_left.png'; // Importing the arrow image

function App() {
  const [currentVideo, setCurrentVideo] = useState(videoSource1);
  const [showButtons, setShowButtons] = useState(true); // Buttons initially visible
  const [activeButton, setActiveButton] = useState(4); // Set first button active by default
  const [fade, setFade] = useState(false);
  const [progress, setProgress] = useState(0);
  const [showArrow, setShowArrow] = useState(true); // Control arrow visibility
  const videoRef = useRef(null);
  const timeoutRef = useRef(null);
  const arrowTimeoutRef = useRef(null); // New ref for arrow timeout

  // Function to reset the inactivity timeout
  const resetTimeout = () => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    if (arrowTimeoutRef.current) {
      clearTimeout(arrowTimeoutRef.current);
    }
    setShowButtons(true); // Show buttons when interaction is detected
    setShowArrow(true); // Ensure arrow is fully visible when interaction is detected
    timeoutRef.current = setTimeout(() => {
      setShowButtons(false); // Hide buttons after 3 seconds of inactivity
      // After 2 seconds of buttons disappearing, reduce arrow opacity to 15%
      arrowTimeoutRef.current = setTimeout(() => {
        setShowArrow(false);
      }, 2000); // 2-second delay
    }, 3000);
  };

  useEffect(() => {
    const video = videoRef.current;
    if (!video) return;

    const updateProgress = () => {
      const progress = (video.currentTime / video.duration) * 100;
      setProgress(progress);
    };

    video.addEventListener('timeupdate', updateProgress);

    return () => {
      video.removeEventListener('timeupdate', updateProgress);
    };
  }, [currentVideo]);

  useEffect(() => {
    const handleScreenInteraction = () => {
      setShowButtons(true); // Show buttons when there is interaction
      setShowArrow(true); // Show arrow immediately on interaction
      resetTimeout(); // Reset the inactivity timer
    };

    // Listen for user interactions (clicks or touches)
    document.addEventListener('click', handleScreenInteraction);
    document.addEventListener('touchstart', handleScreenInteraction);

    // Set initial timeout
    resetTimeout();

    return () => {
      document.removeEventListener('click', handleScreenInteraction);
      document.removeEventListener('touchstart', handleScreenInteraction);
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      if (arrowTimeoutRef.current) {
        clearTimeout(arrowTimeoutRef.current);
      }
    };
  }, []);

  const handleButtonClick = (videoSrc, buttonIndex) => {
    const video = videoRef.current;

    if (currentVideo !== videoSrc) {
      // If the clicked button corresponds to a different video, fade out and switch videos
      setFade(true);
      setActiveButton(buttonIndex);

      setTimeout(() => {
        setCurrentVideo(videoSrc);
        setFade(false);
      }, 1000);
    } else {
      // If the same video is clicked, restart the video
      video.currentTime = 0; // Restart video
      video.play(); // Ensure the video is playing
    }

    // Ensure the button state is always updated, even if it's the current video
    setActiveButton(buttonIndex);
  };

  const handleSeek = (e) => {
    const video = videoRef.current;
    const seekTime = (e.target.value / 100) * video.duration;
    video.currentTime = seekTime;
    setProgress(e.target.value);
  };

  // Ripple effect logic
  const handleRippleEffect = (event) => {
    const button = event.currentTarget;
    const ripple = document.createElement('span');
    const diameter = Math.max(button.clientWidth, button.clientHeight);
    const radius = diameter / 2;

    ripple.style.width = ripple.style.height = `${diameter}px`;
    ripple.style.left = `${event.clientX - button.offsetLeft - radius}px`;
    ripple.style.top = `${event.clientY - button.offsetTop - radius}px`;
    ripple.classList.add('ripple');

    const rippleContainer = button.getElementsByClassName('ripple-container')[0];
    rippleContainer.appendChild(ripple);

    setTimeout(() => {
      ripple.remove();
    }, 600);
  };

  return (
    <div className="App">
      {/* Background image container */}
      <div className="background-container">
        <div className="background-image" />
      </div>

      {/* Video element */}
      <video
        ref={videoRef}
        autoPlay
        loop={currentVideo === videoSource1}  // Only loop the first video
        muted={currentVideo === videoSource1} // Mute the first video
        key={currentVideo}
        className={`background-video ${fade ? 'fade-out' : 'fade-in'}`}
        onEnded={() => {
          // When a video ends and it's not the first one, switch back to the first video
          if (currentVideo !== videoSource1) {
            setCurrentVideo(videoSource1);
          }
        }}
      >
        <source src={currentVideo} type="video/mp4" />
      </video>

      {/* Arrow Left Menu Button */}
      <img
        src={arrowLeft}
        alt="Menu"
        className={`menu-button ${showArrow ? '' : 'hidden'} ${showButtons ? 'rotated' : ''}`} // Add the rotated class when buttons are visible
        style={{
          opacity: showArrow ? (showButtons ? 0.5 : 0.5) : 0.1,
        }}
        onClick={() => console.log('Menu button clicked')}
      />

      {/* Progress Bar */}
      <input
        type="range"
        className={`progress-bar ${showButtons ? 'visible' : 'hidden'}`}
        min="0"
        max="100"
        value={progress}
        onChange={handleSeek}
      />

      {/* Buttons with Ripple Effect */}
      <div className={`content ${showButtons ? 'visible' : 'hidden'}`}>
        <button
          className={`video-button ${activeButton === 4 ? 'active' : ''}`}
          onClick={(e) => { handleButtonClick(videoSource1, 4); handleRippleEffect(e); }}
        >
          <div className="ripple-container"></div>
          Connected Services
        </button>
        <button
          className={`video-button ${activeButton === 1 ? 'active' : ''}`}
          onClick={(e) => { handleButtonClick(videoSource2, 1); handleRippleEffect(e); }}
        >
          <div className="ripple-container"></div>
          Battery Intelligence
        </button>
        <button
          className={`video-button ${activeButton === 2 ? 'active' : ''}`}
          onClick={(e) => { handleButtonClick(videoSource3, 2); handleRippleEffect(e); }}
        >
          <div className="ripple-container"></div>
          The Benefits
        </button>
        <button
          className={`video-button ${activeButton === 3 ? 'active' : ''}`}
          onClick={(e) => { handleButtonClick(videoSource4, 3); handleRippleEffect(e); }}
        >
          <div className="ripple-container"></div>
          THE FUTURE
        </button>
      </div>
    </div>
  );
}

export default App;
